import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { SuccessCases } from '../../../components/SuccessCases'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import banner1 from '../../../images/pages/sgi/banner1.jpg'
import {
  faGlobe,
  faUserCog,
  faTasks,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons'

const slides = [
  {
    title: <>NORMAS DE GESTÃO ISO</>,
    description: (
      <>
        Vamos diagnosticar, medir o nível de aderência, e implementar as normas
        de gestão ISO (9001 / 14001 / 45001 / OUTRAS) em sua organização.
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const cases = [
  {
    title: `CTG Brasil`,
    text: `Implementação das normas 9001 / 14001 / 45001 / 55001`,
    quantity: 500,
  },
  {
    title: `UNIMED Botucatu`,
    text: `Adequação para atendimento a resolução normativa RN 443 / 2019`,
    quantity: 100,
  },
  {
    title: `Biosev`,
    text: `Documentação dos processos de Originação e transformação de atividades manuais`,
    quantity: 60,
  },
]

const SGI: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faTasks,
      title: `Diagnóstico de Aderência às Normas`,
      description: (
        <>
          Faremos um diagnóstico preciso com base na norma desejada, e
          forneceremos o nível de aderência da sua organização com relação aos
          itens normativos, bem como um plano de ação estruturado que direcione
          os seus esforços na busca pela obtenção do certificado ISO.
        </>
      ),
    },
    {
      faIcon: faSitemap,
      title: `Auditoria Interna`,
      description: (
        <>
          Seremos a empresa “parceira” de sua organização nessa tarefa
          normativa, e de suma importância. Nossa metodologia consiste na
          execução ponta-a-ponta de auditoria dos processos (planejamento,
          execução e encerramento).
        </>
      ),
    },
    {
      faIcon: faGlobe,
      title: `Implementação de Normas de Gestão ISO`,
      description: (
        <>
          Nossa equipe irá te acompanhar desde o diagnóstico até a implementação
          de todas as ações para obtenção do certificado, com foco em
          padronização e melhoria de processos sustentáveis.
        </>
      ),
    },
    {
      faIcon: faUserCog,
      title: `Treinamentos`,
      description: (
        <>
          Forneceremos para sua organização, treinamentos de introdução e
          interpretação de normas de gestão ISO, conforme tema específico (
          <a
            href="https://blog.valoremelhoria.com.br/2021-05-20-as-normas-de-gest%C3%A3o-da-iso-e-sua-associa%C3%A7%C3%A3o-com-o-ciclo-pdca/"
            target="_blank"
            rel="noopener"
            className={styles.innerTextLink}
          >
            9001
          </a>{' '}
          /{' '}
          <a
            href="https://blog.valoremelhoria.com.br/2021-05-20-desenvolvimento-sustent%C3%A1vel-iso-14001-2015/"
            target="_blank"
            rel="noopener"
            className={styles.innerTextLink}
          >
            14001
          </a>
          /{' '}
          <a
            href="https://blog.valoremelhoria.com.br/2021-05-20-iso-45001-sistemas-de-gest%C3%A3o-de-sa%C3%BAde-e-seguran%C3%A7a-ocupacionais/"
            target="_blank"
            rel="noopener"
            className={styles.innerTextLink}
          >
            45001
          </a>{' '}
          / 55001 / 31000 / outras).
        </>
      ),
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Planejamento',
      description: (
        <>
          A fase do planejamento é um processo comercial. Neste momento ainda
          não houve a contratação do produto. O objetivo desta etapa é alinhar
          todas as expectativas do projeto e os objetivos junto ao contratante.
          <AnchorLink to={`${location.pathname}#contato`}>
            {' '}
            Agende aqui essa etapa sem compromisso.
          </AnchorLink>
        </>
      ),
    },
    {
      count: 2,
      title: 'Diagnóstico',
      description: (
        <>
          Nessa etapa, iremos coletar informações através de entrevistas com os
          pontos focais de cada processo, fazendo perguntas e tomando nota das
          constatações. É neste momento que serão registradas as
          “conformidades”, oportunidades de melhorias, e as não conformidades
          (caso sejam identificadas), ou seja, situações que ocorreram em
          desacordo com as normas, leis, processo e procedimento padronizado.
        </>
      ),
    },
    {
      count: 3,
      title: 'Análise e Melhoria',
      description: (
        <>
          Após concluída a execução do diagnóstico, será apresentado um
          relatório que demonstra o nível de maturidade de sua organização com
          relação aos requisitos normativos, bem como um plano de ação que
          direcione os esforços para obtenção da certificação, dentro do prazo
          pretendido pelo cliente.
        </>
      ),
    },
    {
      count: 4,
      title: 'Implementação',
      description: (
        <>
          Muito importante para a melhoria não ficar apenas na teoria. A Valore
          utiliza as técnicas de GMO do instituto HCMI para engajar e “puxar” as
          mudanças para a empresa realmente implantar o que foi aprovado. Com
          reuniões periódicas unem-se os agentes das mudanças (comitê) para
          reportarem às necessidades ou as dificuldades da implantação.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="Sistema de Gestāo Integrada - SGI - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="
              Buscar soluções para implementar as normas de gestão ISO em nossos clientes através de um diagnóstico preciso, ferramentas que medem o nível de aderência aos requisitos normativos, e implementando ações de padronização e melhoria de processos."
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
          />
        </div>
      </section>

      <SuccessCases listCases={cases} />
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default SGI
